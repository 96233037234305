<template>
  <div class="container">
    <section class="hero is-black is-large has-bg-img13 pagebg">
      <div class="hero-body">
        <div class="svganim">
          <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
           width="600.000000pt" height="600.000000pt" viewBox="0 0 600.000000 600.000000"
           preserveAspectRatio="xMidYMid meet">
          <metadata>
          Created by potrace 1.11, written by Peter Selinger 2001-2013
          </metadata>
          <g transform="translate(605.000000,60.000000) rotate(180) scale(6.500,-6.5000)"
          fill="#000000" stroke="none">
          <path class="path" fill="transparent" stroke="#FFFFFF" stroke-width="0.16" stroke-miterlimit="1"
          d="M16.8,54l2.6,2.6l-5.1,5.1l-2.6-2.6c-15.6-15.6-15.6-41,0-56.6L14.3,0l5.1,5.1l-2.6,2.6C4.1,20.5,4.1,41.2,16.8,54L16.8,54z
 M68.3,2.6L65.7,0l-5.1,5.1l2.6,2.6c12.8,12.8,12.8,33.5,0,46.3l-2.6,2.6l5.1,5.1l2.6-2.6C83.9,43.5,83.9,18.2,68.3,2.6L68.3,2.6z
 M59.7,11.1l-2.6-2.6L52,13.7l2.6,2.6c3.9,3.9,6,9.1,6,14.6s-2.1,10.7-6,14.6L52,48l5.1,5.1l2.6-2.6c5.3-5.3,8.2-12.3,8.2-19.7
S65,16.4,59.7,11.1L59.7,11.1z M28,13.7l-5.1-5.1l-2.6,2.6c-5.3,5.3-8.2,12.3-8.2,19.7s2.9,14.4,8.2,19.7l2.6,2.6L28,48l-2.6-2.6
c-3.9-3.9-6-9.1-6-14.6s2.1-10.7,6-14.6L28,13.7L28,13.7z M50.9,30.9c0,6-4.9,10.9-10.9,10.9s-10.9-4.9-10.9-10.9S34,19.9,40,19.9
C46,19.9,50.9,24.8,50.9,30.9z M43.6,30.9c0-2-1.6-3.6-3.6-3.6s-3.6,1.6-3.6,3.6s1.6,3.6,3.6,3.6S43.6,32.9,43.6,30.9z"/>
          </g>
          </svg>
        </div>
        <div class="svgnoanim">
          <img src="@/assets/broadcast2.svg" alt="TheCompagnie | FPV | DRONE | MEDIA" style="opacity:0.2; max-height:8em;">
        </div>
        <div class="container has-text-left">
          <h1 class="title">FPV Live Streaming</h1>
          <h2 class="subtitle">At the heart of the live action</h2>
        </div>
      </div>
    </section>

    <!-- Section: Introduction -->
    <div class="container" style="padding-top: 4em;">
      <div class="columns is-multiline">
        <div class="column is-12 has-text-left" style="padding-right: 3em; padding-left: 3em; margin-top: 1em;">
          <h3 class="subtitle">Innovative technology for optimal immersion</h3>
          <p>
            Discover our <b>live streaming solution</b>, designed to provide dynamic and captivating shots in real-time. Perfect for <b>extreme sports, motorsports, concerts, festivals</b>, our technology ensures a <b>total immersion</b> during your events.
          </p>
          <br>
          <p>
            With a <b>unique stabilization system</b>, we deliver exceptional <b>image quality</b> while maintaining <b>low latency</b>, even in complex environments. Enjoy a <b>live stream</b> that captures your audience's attention and transforms each event into a <b>memorable experience</b>.
          </p>
          <hr>
        </div>

        <!-- Section: Stabilization Comparison -->
        <div class="column is-12 has-text-left" style="padding-right: 3em; padding-left: 3em; margin-top: 1em;">
          <h3 class="subtitle">Stabilized High-Definition Broadcast</h3>
          <p>
            Traditional FPV drone broadcasting systems have major limitations: image quality, excessive weight incompatible with small drones, stabilization causing latency, or legal constraints.
            <br><br>
            Our solution overcomes these challenges by offering <b>exceptional stabilization</b>, <b>high image quality</b>, and <b>minimal latency</b>, while remaining compact and lightweight, similar to an action camera.
            <br>
            This allows you to achieve <b>dynamic and immersive shots</b>, ideal for live events, whether it's extreme sports, motorsports, concerts, or festivals.
          </p>
          <br>
          <div class="video-container" id="comparisonContainer">
            <!-- Bottom video (SD - Traditional FPV Broadcast) -->
            <video id="videoBottom" src="/demo/compareSD.mp4" autoplay muted loop playsinline preload="auto"></video>

            <!-- Top video (HD - Our Broadcast) -->
            <div class="video-top" id="videoTop">
              <video src="/demo/compareHD.mp4" autoplay muted loop playsinline preload="auto"></video>
            </div>

            <!-- The slider for comparison -->
            <div class="slider" id="slider">
              <div class="slider-icon" id="sliderIcon">
                <img src="@/assets/slider.svg" alt="Slider Icon for Video Comparison">
              </div>
            </div>

            <!-- Labels -->
            <div class="video-label video-label-left">Our FPV Broadcast</div>
            <div class="video-label video-label-right">Traditional FPV Broadcast</div>
          </div>
          <p><small>Footage captured live with our stabilization system</small></p>
          <hr>
        </div>

        <!-- Section: Technical Features and Flexibility -->
        <div class="column is-12" style="padding-right: 3em; padding-left: 3em; margin-top: 1em;">
          <h3 class="subtitle has-text-left">Technical Features</h3>
          <div class="columns is-multiline">

            <!-- 1080p / 1080i resolution at 25 or 50 fps -->
            <div class="column is-6">
              <div class="media">
                <figure class="media-left">
                  <img src="@/assets/resolution.svg" alt="1080p/i Resolution" width="40">
                </figure>
                <div class="media-content has-text-justified">
                  <p>
                    <b>Live streaming in 1080p or 1080i</b><br>Flexibility in broadcasting in <b>1080p (progressive) or 1080i (interlaced)</b> with a choice of <b>25 to 50 frames per second</b>. This allows you to adjust the quality of the stream according to the event's requirements.
                  </p>
                </div>
              </div>
            </div>

            <!-- 75° FOV and 100° wide angle -->
            <div class="column is-6">
              <div class="media">
                <figure class="media-left">
                  <img src="@/assets/focallength.svg" alt="Focal Length" width="40">
                </figure>
                <div class="media-content has-text-justified">
                  <p>
                    <b>Focal length options</b><br>A <b>24 mm focal length</b> or a <b>wide angle equivalent to 15 mm</b> to capture wider views or close-up shots depending on the shooting needs.
                  </p>
                </div>
              </div>
            </div>

            <!-- 3-axis stabilization -->
            <div class="column is-6">
              <div class="media">
                <figure class="media-left">
                  <img src="@/assets/stabilized.svg" alt="Stabilization" width="40">
                </figure>
                <div class="media-content has-text-justified">
                  <p>
                    <b>3-axis stabilization</b><br>An image without vibrations, preserving the <b>FPV effect</b>, and full stabilization for shots with a <b>"cable cam" effect</b>. The dual operator option allows precise framing control, providing a perfect solution for unique productions.
                  </p>
                </div>
              </div>
            </div>

            <!-- Adaptability to different types of drones -->
            <div class="column is-6">
              <div class="media">
                <figure class="media-left">
                  <img src="@/assets/adaptivity.svg" alt="Adaptability" width="40">
                </figure>
                <div class="media-content has-text-justified">
                  <p>
                    <b>Adaptability</b><br>Adapting to a wide range of drones, from traditional FPV drones to smaller models for <b>confined spaces</b>, and even <b>high-speed drones</b> for capturing fast-paced action scenes.
                  </p>
                </div>
              </div>
            </div>

            <!-- Low latency -->
            <div class="column is-6">
              <div class="media">
                <figure class="media-left">
                  <img src="@/assets/latency.svg" alt="Latency" width="40">
                </figure>
                <div class="media-content has-text-justified">
                  <p>
                    <b>Low latency</b><br>Our technology guarantees <b>low latency</b>, ideal for live events. Your viewers will experience the <b>action without delay</b>, in perfect sync with the real event.
                  </p>
                </div>
              </div>
            </div>

            <!-- Long-range transmission -->
            <div class="column is-6">
              <div class="media">
                <figure class="media-left">
                  <img src="@/assets/broadcast.svg" alt="Transmission" width="40">
                </figure>
                <div class="media-content has-text-justified">
                  <p>
                    <b>Long-range transmission</b><br>Even in complex environments, our system ensures a <b>reliable transmission</b> up to 7 km. Whether in urban areas or large open spaces, the <b>video feed remains stable and high-quality</b>.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <hr>
        </div>

        <!-- Section: 4K Recording at 100 fps -->
        <div class="column is-12 has-text-left" style="padding-right: 3em; padding-left: 3em;  margin-top: 1em;">
          <h3 class="subtitle">Simultaneous recording for post-production</h3>
          <p class="has-text-justified" style="margin-bottom: 1em;">
            Our system not only offers <b>live streaming in 1080</b>. It also allows you to capture every moment in <b>4K at up to 100 frames per second</b>, producing smooth and detailed slow-motion footage. Ideal for event highlights or <b>post-production use</b>.
          </p>
          <div class="video-container" id="slowmoContainer">
            <!-- Video for slow motion comparison -->
            <video id="demoSlowmo" src="/demo/demoSlowmo.mp4" autoplay muted loop playsinline preload="auto"></video>

            <!-- Labels -->
            <div class="video-label video-label-left">1x Speed Live</div>
            <div class="video-label video-label-right">0.25x Speed Post-Production</div>
          </div>
          <hr>
        </div>

        <!-- Section: Flexibility and Technical Expertise -->
        <div class="column is-12 has-text-left" style="padding: 1em 3em; margin-bottom: 3em;">
          <h3 class="subtitle has-text-weight-bold">Flexibility</h3>
          <p>
            Our expert FPV drone pilots ensure <b>safety</b> and <b>legal compliance</b> during your events, whether in urban areas, large festival stages, or complex natural environments. We tailor our interventions to each situation while strictly following regulations.
          </p>
          <br>
          <p>
            Our technology is designed to offer total flexibility. Whether in confined spaces, on small stages, or in vast areas like mountains, forests, or over water, our drones guarantee <b>optimal stability</b>, even in windy conditions, with radio interference, or at high altitudes. This ensures an impeccable image quality, regardless of the conditions.
          </p>

          <!-- Icons for environments -->
          <div class="icons-environments" style="margin-top: 2em;">
            <div class="columns is-multiline is-centered is-mobile">
              <div class="column is-4-tablet is-3-desktop has-text-centered">
                <img src="@/assets/mountain.svg" alt="Mountain" style="width: 50px; height: 50px;">
                <p class="has-text-weight-semibold">High altitude</p>
              </div>
              <div class="column is-4-tablet is-3-desktop has-text-centered">
                <img src="@/assets/city.svg" alt="City" style="width: 50px; height: 50px;">
                <p class="has-text-weight-semibold">Urban environment</p>
              </div>
              <div class="column is-4-tablet is-3-desktop has-text-centered">
                <img src="@/assets/stages.svg" alt="Concert" style="width: 50px; height: 50px;">
                <p class="has-text-weight-semibold">Concerts & Festivals</p>
              </div>
              <div class="column is-4-tablet is-3-desktop has-text-centered">
                <img src="@/assets/interferences.svg" alt="Radio complexity" style="width: 50px; height: 50px;">
                <p class="has-text-weight-semibold">Interference environment</p>
              </div>
              <div class="column is-4-tablet is-3-desktop has-text-centered">
                <img src="@/assets/wind.svg" alt="High winds" style="width: 50px; height: 50px;">
                <p class="has-text-weight-semibold">High winds</p>
              </div>
              <div class="column is-4-tablet is-3-desktop has-text-centered">
                <img src="@/assets/forest.svg" alt="Small spaces" style="width: 50px; height: 50px;">
                <p class="has-text-weight-semibold">Complex small spaces</p>
              </div>
              <div class="column is-4-tablet is-3-desktop has-text-centered">
                <img src="@/assets/water.svg" alt="Over water" style="width: 50px; height: 50px;">
                <p class="has-text-weight-semibold">Marine environment</p>
              </div>
            </div>
          </div>
        </div>

        <!-- Call to Action Section -->
        <div class="column is-12 has-text-centered">
          <h4 class="subtitle">
            Schedule a test session to experience it for yourself and transform your events:
          </h4>
          <br>
          <button class="button is-black is-outlined is-medium" @click="mailtocontact">
            <span class="icon is-medium">
              <i class="fa fa-envelope"></i>
            </span>
            <span>Contact Us</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Live',
  methods: {
    mailtocontact() {
      window.location.href = "mailto:contact@thecompagnie.eu"
    },
  },
  mounted() {
    // Comparison slider functionality
    const videoTop = document.getElementById('videoTop');
    const slider = document.getElementById('slider');
    const sliderIcon = document.getElementById('sliderIcon');
    const comparisonContainer = document.getElementById('comparisonContainer');
    let isDragging = false;

    // Function to update the slider position
    const updateSliderPosition = (x, rect, width) => {
      if (x < 0) x = 0;
      if (x > width) x = width;
      videoTop.style.clipPath = `inset(0px ${width - x}px 0px 0px)`; // Reveal video
      slider.style.left = x + 'px';
    };

    // Set initial slider to 75% of the width
    const setInitialSlider = () => {
      const rect = comparisonContainer.getBoundingClientRect();
      const width = rect.width;
      updateSliderPosition(0.60 * width, rect, width);
    };

    setInitialSlider();

    // Mouse and touch events for dragging
    const onMouseDown = () => { isDragging = true; };
    const onMouseUp = () => { isDragging = false; };

    const onMouseMove = (e) => {
      if (!isDragging) return;
      const rect = comparisonContainer.getBoundingClientRect();
      const x = e.clientX - rect.left; // Get mouse X coordinate relative to the container
      const width = rect.width; // Total width of the container
      updateSliderPosition(x, rect, width);
    };

    // Touch event equivalent of mouse move
    const onTouchMove = (e) => {
      if (!isDragging) return;
      const rect = comparisonContainer.getBoundingClientRect();
      const touch = e.touches[0]; // Use the first touch point
      const x = touch.clientX - rect.left; // Get touch X coordinate relative to the container
      const width = rect.width; // Total width of the container
      updateSliderPosition(x, rect, width);
    };

    // Add mouse events
    slider.addEventListener('mousedown', onMouseDown);
    document.addEventListener('mouseup', onMouseUp);
    document.addEventListener('mousemove', onMouseMove);

    // Add touch events
    slider.addEventListener('touchstart', onMouseDown);
    document.addEventListener('touchend', onMouseUp);
    document.addEventListener('touchmove', onTouchMove);
  }
}
</script>

<style lang="scss" scoped>
.video-container {
  position: relative;
  max-width: 100%; /* Ensure the container does not exceed page width */
  height: auto;
  aspect-ratio: 16 / 9; /* Maintain 16:9 ratio for videos */
  margin: 0 auto;
  overflow: hidden;
}

.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1; /* Ensure the video stays behind the top video */
}

/* Upper video styling */
.video-top {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%; /* Make sure the video covers the entire container */
  z-index: 2;
  clip-path: inset(0px 25% 0px 0px); /* Set initial clip to 75% to the right */
}

/* Slider styling */
.slider {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 75%; /* Set slider to start at 75% */
  width: 5px;
  background-color: #fff;
  cursor: ew-resize;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Icon in the center of the slider */
.slider-icon {
  position: absolute;
  top: 50%;
  width: 2.5em;
  height: 2.5em;
  transform: translateY(-50%);
  cursor: pointer; /* Makes the SVG clickable */
}

.slider-icon img {
  pointer-events: none;
}

/* Labels for the videos */
.video-label {
  position: absolute;
  font-size: 0.9em !important;
  font-weight: bold;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px 10px;
  z-index: 4;
}

@media screen and (max-width: 768px){
.video-label {
  font-size: 0.5em !important;
}
}

.video-label-left {
  bottom: 10px;
  left: 10px;
}

.video-label-right {
  bottom: 10px;
  right: 10px;
}
</style>
