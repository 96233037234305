<template>
  <div class="container">
    <section class="hero is-black is-large has-bg-img13 pagebg">
      <div class="hero-body">
        <div class="svganim">
          <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
           width="600.000000pt" height="600.000000pt" viewBox="0 0 600.000000 600.000000"
           preserveAspectRatio="xMidYMid meet">
          <metadata>
          Created by potrace 1.11, written by Peter Selinger 2001-2013
          </metadata>
          <g transform="translate(605.000000,60.000000) rotate(180) scale(6.500,-6.5000)"
          fill="#000000" stroke="none">
          <path class="path" fill="transparent" stroke="#FFFFFF" stroke-width="0.16" stroke-miterlimit="1"
          d="M16.8,54l2.6,2.6l-5.1,5.1l-2.6-2.6c-15.6-15.6-15.6-41,0-56.6L14.3,0l5.1,5.1l-2.6,2.6C4.1,20.5,4.1,41.2,16.8,54L16.8,54z
 M68.3,2.6L65.7,0l-5.1,5.1l2.6,2.6c12.8,12.8,12.8,33.5,0,46.3l-2.6,2.6l5.1,5.1l2.6-2.6C83.9,43.5,83.9,18.2,68.3,2.6L68.3,2.6z
 M59.7,11.1l-2.6-2.6L52,13.7l2.6,2.6c3.9,3.9,6,9.1,6,14.6s-2.1,10.7-6,14.6L52,48l5.1,5.1l2.6-2.6c5.3-5.3,8.2-12.3,8.2-19.7
S65,16.4,59.7,11.1L59.7,11.1z M28,13.7l-5.1-5.1l-2.6,2.6c-5.3,5.3-8.2,12.3-8.2,19.7s2.9,14.4,8.2,19.7l2.6,2.6L28,48l-2.6-2.6
c-3.9-3.9-6-9.1-6-14.6s2.1-10.7,6-14.6L28,13.7L28,13.7z M50.9,30.9c0,6-4.9,10.9-10.9,10.9s-10.9-4.9-10.9-10.9S34,19.9,40,19.9
C46,19.9,50.9,24.8,50.9,30.9z M43.6,30.9c0-2-1.6-3.6-3.6-3.6s-3.6,1.6-3.6,3.6s1.6,3.6,3.6,3.6S43.6,32.9,43.6,30.9z"/>
          </g>
          </svg>
        </div>
        <div class="svgnoanim">
          <img src="@/assets/broadcast2.svg" alt="TheCompagnie | FPV | DRONE | MEDIO" style="opacity:0.2; max-height:8em;">
        </div>
        <div class="container has-text-left">
          <h1 class="title">Transmisión en vivo FPV</h1>
          <h2 class="subtitle">El corazón de la acción en vivo</h2>
        </div>
      </div>
    </section>

    <!-- Sección: Introducción -->
    <div class="container" style="padding-top: 4em;">
      <div class="columns is-multiline">
        <div class="column is-12 has-text-left" style="padding-right: 3em; padding-left: 3em; margin-top: 1em;">
          <h3 class="subtitle">Tecnología innovadora para una inmersión óptima</h3>
          <p>
            Descubre nuestra <b>solución de transmisión en vivo</b>, diseñada para ofrecer tomas dinámicas y cautivadoras en tiempo real. Ideal para <b>deportes extremos, deportes de motor, conciertos, festivales</b>, nuestra tecnología garantiza una <b>inmersión total</b> en tus eventos.
          </p>
          <br>
          <p>
            Con un <b>sistema de estabilización único</b>, aseguramos una calidad de <b>imagen excepcional</b> manteniendo una <b>baja latencia</b>, incluso en entornos complejos. Disfruta de una <b>transmisión en vivo</b> que captura la atención de tu audiencia y transforma cada evento en una <b>experiencia inolvidable</b>.
          </p>
          <hr>
        </div>

        <!-- Sección: Comparación de Estabilización -->
        <div class="column is-12 has-text-left" style="padding-right: 3em; padding-left: 3em; margin-top: 1em;">
          <h3 class="subtitle">Transmisión en Alta Definición estabilizada</h3>
          <p>
            Los sistemas de transmisión en drones FPV tradicionales presentan limitaciones importantes: calidad de imagen, peso excesivo incompatible con drones pequeños, estabilización con alta latencia o restricciones legales.
            <br><br>
            Nuestra solución supera estos desafíos ofreciendo una <b>estabilización excepcional</b>, una <b>alta calidad de imagen</b> y una <b>latencia mínima</b>, siendo compacta y ligera, comparable a una cámara de acción.
            <br>
            Esto te permite obtener <b>tomas dinámicas e inmersivas</b>, ideales para eventos en vivo, ya sea deportes extremos o de motor, conciertos o festivales.
          </p>
          <br>
          <div class="video-container" id="comparisonContainer">
            <!-- Video inferior (SD - Transmisión FPV clásica) -->
            <video id="videoBottom" src="/demo/compareSD.mp4" autoplay muted loop playsinline preload="auto"></video>

            <!-- Video superior (HD - Nuestra transmisión) -->
            <div class="video-top" id="videoTop">
              <video src="/demo/compareHD.mp4" autoplay muted loop playsinline preload="auto"></video>
            </div>

            <!-- Control deslizante para la comparación -->
            <div class="slider" id="slider">
              <div class="slider-icon" id="sliderIcon">
                <img src="@/assets/slider.svg" alt="Icono del deslizador para comparación de videos">
              </div>
            </div>

            <!-- Etiquetas -->
            <div class="video-label video-label-left">Nuestra transmisión FPV</div>
            <div class="video-label video-label-right">Transmisión FPV clásica</div>
          </div>
          <p><small>Imágenes capturadas en vivo con nuestro sistema de estabilización</small></p>
          <hr>
        </div>

        <!-- Sección: Características Técnicas y Flexibilidad -->
        <div class="column is-12" style="padding-right: 3em; padding-left: 3em; margin-top: 1em;">
          <h3 class="subtitle has-text-left">Características técnicas</h3>
          <div class="columns is-multiline">

            <!-- Resolución 1080p / 1080i a 25 o 50 fps -->
            <div class="column is-6">
              <div class="media">
                <figure class="media-left">
                  <img src="@/assets/resolution.svg" alt="Resolución 1080p/i" width="40">
                </figure>
                <div class="media-content has-text-justified">
                  <p>
                    <b>Transmisión en vivo en 1080p o 1080i</b><br>Una flexibilidad de transmisión en <b>1080p (progresivo) o 1080i (entrelazado)</b> con la opción de <b>25 a 50 cuadros por segundo</b>. Esto permite ajustar la calidad de la transmisión según las necesidades del evento.
                  </p>
                </div>
              </div>
            </div>

            <!-- Focal de 75° y gran angular de 100° -->
            <div class="column is-6">
              <div class="media">
                <figure class="media-left">
                  <img src="@/assets/focallength.svg" alt="Longitud focal" width="40">
                </figure>
                <div class="media-content has-text-justified">
                  <p>
                    <b>Opciones de longitud focal</b><br>Una longitud focal de <b>24 mm</b> o un gran angular <b>equivalente a 15 mm</b> para capturar vistas más amplias o tomas cercanas según las necesidades de la producción.
                  </p>
                </div>
              </div>
            </div>

            <!-- Estabilización de 3 ejes -->
            <div class="column is-6">
              <div class="media">
                <figure class="media-left">
                  <img src="@/assets/stabilized.svg" alt="Estabilización" width="40">
                </figure>
                <div class="media-content has-text-justified">
                  <p>
                    <b>Estabilización en 3 ejes</b><br>Imagen sin vibraciones, manteniendo el efecto <b>FPV</b>, y una estabilización completa para tomas con efecto <b>"cable cam"</b>. La opción de doble operador permite un control preciso del encuadre, ofreciendo una solución perfecta para una producción única.
                  </p>
                </div>
              </div>
            </div>

            <!-- Adaptabilidad a diferentes tipos de drones -->
            <div class="column is-6">
              <div class="media">
                <figure class="media-left">
                  <img src="@/assets/adaptivity.svg" alt="Adaptabilidad" width="40">
                </figure>
                <div class="media-content has-text-justified">
                  <p>
                    <b>Adaptabilidad</b><br>Se adapta a una gran variedad de drones, desde drones FPV tradicionales hasta modelos más pequeños para <b>espacios reducidos</b>, y drones de <b>alta velocidad</b> para capturar escenas de acción rápida.
                  </p>
                </div>
              </div>
            </div>

            <!-- Baja latencia -->
            <div class="column is-6">
              <div class="media">
                <figure class="media-left">
                  <img src="@/assets/latency.svg" alt="Latencia" width="40">
                </figure>
                <div class="media-content has-text-justified">
                  <p>
                    <b>Baja latencia</b><br>Nuestra tecnología garantiza una <b>baja latencia</b>, ideal para eventos en vivo. Tus espectadores vivirán la <b>acción sin retraso</b>, en perfecta sincronización con el evento real.
                  </p>
                </div>
              </div>
            </div>

            <!-- Transmisión de largo alcance -->
            <div class="column is-6">
              <div class="media">
                <figure class="media-left">
                  <img src="@/assets/broadcast.svg" alt="Transmisión" width="40">
                </figure>
                <div class="media-content has-text-justified">
                  <p>
                    <b>Transmisión de largo alcance</b><br>Incluso en entornos complejos, nuestro sistema asegura una <b>transmisión confiable</b> a una distancia de hasta 7 km. Ya sea en la ciudad o en espacios abiertos, el <b>flujo de video se mantiene estable y de alta calidad</b>.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <hr>
        </div>

        <!-- Sección: Grabación 4K a 100 fps -->
        <div class="column is-12 has-text-left" style="padding-right: 3em; padding-left: 3em;  margin-top: 1em;">
          <h3 class="subtitle">Grabación simultánea para postproducción</h3>
          <p class="has-text-justified" style="margin-bottom: 1em;">
            Nuestro sistema no solo se enfoca en la <b>transmisión en vivo en 1080</b>. También te permite capturar cada instante en <b>4K hasta 100 cuadros por segundo</b>, para producir ralentizados fluidos y detallados. Ideal para resúmenes de eventos o para <b>usos en postproducción</b>.
          </p>
          <div class="video-container" id="slowmoContainer">
            <!-- Video para la comparación en cámara lenta -->
            <video id="demoSlowmo" src="/demo/demoSlowmo.mp4" autoplay muted loop playsinline preload="auto"></video>

            <!-- Etiquetas -->
            <div class="video-label video-label-left">Velocidad 1x en vivo</div>
            <div class="video-label video-label-right">Velocidad 0.25x en postproducción</div>
          </div>
          <hr>
        </div>

        <!-- Sección: Flexibilidad y dominio técnico -->
        <div class="column is-12 has-text-left" style="padding: 1em 3em; margin-bottom: 3em;">
          <h3 class="subtitle has-text-weight-bold">Flexibilidad</h3>
          <p>
            Nuestros pilotos expertos en drones FPV garantizan la <b>seguridad</b> y el <b>cumplimiento legal</b> en tus eventos, ya sean en espacios urbanos, grandes escenarios de festivales o entornos naturales complejos. Adaptamos nuestras intervenciones a cada situación respetando rigurosamente las regulaciones.
          </p>
          <br>
          <p>
            Nuestra tecnología está diseñada para ofrecer una flexibilidad total. Ya sea en espacios reducidos, en pequeños escenarios, o en grandes espacios como montañas, bosques o sobre el agua, nuestros drones garantizan una <b>estabilidad óptima</b>, incluso con viento, interferencias de radio o en alta altitud. Obtendrás así una calidad de imagen impecable, sea cual sea la condición.
          </p>

          <!-- Iconos para los entornos -->
          <div class="icons-environments" style="margin-top: 2em;">
            <div class="columns is-multiline is-centered is-mobile">
              <div class="column is-4-tablet is-3-desktop has-text-centered">
                <img src="@/assets/mountain.svg" alt="Montaña" style="width: 50px; height: 50px;">
                <p class="has-text-weight-semibold">Alta altitud</p>
              </div>
              <div class="column is-4-tablet is-3-desktop has-text-centered">
                <img src="@/assets/city.svg" alt="Ciudad" style="width: 50px; height: 50px;">
                <p class="has-text-weight-semibold">Entorno urbano</p>
              </div>
              <div class="column is-4-tablet is-3-desktop has-text-centered">
                <img src="@/assets/stages.svg" alt="Concierto" style="width: 50px; height: 50px;">
                <p class="has-text-weight-semibold">Conciertos y Festivales</p>
              </div>
              <div class="column is-4-tablet is-3-desktop has-text-centered">
                <img src="@/assets/interferences.svg" alt="Interferencias" style="width: 50px; height: 50px;">
                <p class="has-text-weight-semibold">Entorno con interferencias</p>
              </div>
              <div class="column is-4-tablet is-3-desktop has-text-centered">
                <img src="@/assets/wind.svg" alt="Viento fuerte" style="width: 50px; height: 50px;">
                <p class="has-text-weight-semibold">Viento fuerte</p>
              </div>
              <div class="column is-4-tablet is-3-desktop has-text-centered">
                <img src="@/assets/forest.svg" alt="Espacios reducidos" style="width: 50px; height: 50px;">
                <p class="has-text-weight-semibold">Espacios reducidos complejos</p>
              </div>
              <div class="column is-4-tablet is-3-desktop has-text-centered">
                <img src="@/assets/water.svg" alt="Sobre el agua" style="width: 50px; height: 50px;">
                <p class="has-text-weight-semibold">Entorno marino</p>
              </div>
            </div>
          </div>
        </div>

        <!-- Sección de Llamada a la Acción -->
        <div class="column is-12 has-text-centered">
          <h4 class="subtitle">
            Agenda una sesión de pruebas para experimentarlo por ti mismo y transformar tus eventos:
          </h4>
          <br>
          <button class="button is-black is-outlined is-medium" @click="mailtocontact">
            <span class="icon is-medium">
              <i class="fa fa-envelope"></i>
            </span>
            <span>Contáctanos</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Live',
  methods: {
    mailtocontact() {
      window.location.href = "mailto:contact@thecompagnie.eu"
    },
  },
  mounted() {
    // Funcionalidad del deslizador de comparación
    const videoTop = document.getElementById('videoTop');
    const slider = document.getElementById('slider');
    const sliderIcon = document.getElementById('sliderIcon');
    const comparisonContainer = document.getElementById('comparisonContainer');
    let isDragging = false;

    // Función para actualizar la posición del deslizador
    const updateSliderPosition = (x, rect, width) => {
      if (x < 0) x = 0;
      if (x > width) x = width;
      videoTop.style.clipPath = `inset(0px ${width - x}px 0px 0px)`; // Revelar video
      slider.style.left = x + 'px';
    };

    // Configurar el deslizador inicial al 75% del ancho
    const setInitialSlider = () => {
      const rect = comparisonContainer.getBoundingClientRect();
      const width = rect.width;
      updateSliderPosition(0.60 * width, rect, width);
    };

    setInitialSlider();

    // Eventos de ratón y táctil para arrastrar
    const onMouseDown = () => { isDragging = true; };
    const onMouseUp = () => { isDragging = false; };

    const onMouseMove = (e) => {
      if (!isDragging) return;
      const rect = comparisonContainer.getBoundingClientRect();
      const x = e.clientX - rect.left; // Obtener la coordenada X del ratón relativa al contenedor
      const width = rect.width; // Ancho total del contenedor
      updateSliderPosition(x, rect, width);
    };

    // Evento táctil equivalente al movimiento del ratón
    const onTouchMove = (e) => {
      if (!isDragging) return;
      const rect = comparisonContainer.getBoundingClientRect();
      const touch = e.touches[0]; // Usar el primer punto táctil
      const x = touch.clientX - rect.left; // Obtener la coordenada X del toque relativa al contenedor
      const width = rect.width; // Ancho total del contenedor
      updateSliderPosition(x, rect, width);
    };

    // Añadir eventos del ratón
    slider.addEventListener('mousedown', onMouseDown);
    document.addEventListener('mouseup', onMouseUp);
    document.addEventListener('mousemove', onMouseMove);

    // Añadir eventos táctiles
    slider.addEventListener('touchstart', onMouseDown);
    document.addEventListener('touchend', onMouseUp);
    document.addEventListener('touchmove', onTouchMove);
  }
}
</script>

<style lang="scss" scoped>
.video-container {
  position: relative;
  max-width: 100%; /* Asegura que el contenedor no exceda el ancho de la página */
  height: auto;
  aspect-ratio: 16 / 9; /* Mantener la relación de aspecto 16:9 para los videos */
  margin: 0 auto;
  overflow: hidden;
}

.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1; /* Asegura que el video se mantenga detrás del video superior */
}

/* Estilo del video superior */
.video-top {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%; /* Asegúrate de que el video cubra todo el contenedor */
  z-index: 2;
  clip-path: inset(0px 25% 0px 0px); /* Establece el recorte inicial al 75% a la derecha */
}

/* Estilo del deslizador */
.slider {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 75%; /* Establece el deslizador para comenzar al 75% */
  width: 5px;
  background-color: #fff;
  cursor: ew-resize;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Icono en el centro del deslizador */
.slider-icon {
  position: absolute;
  top: 50%;
  width: 2.5em;
  height: 2.5em;
  transform: translateY(-50%);
  cursor: pointer; /* Hace que el SVG sea clicable */
}

.slider-icon img {
  pointer-events: none;
}

/* Etiquetas para los videos */
.video-label {
  position: absolute;
  font-size: 0.9em !important;
  font-weight: bold;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px 10px;
  z-index: 4;
}

@media screen and (max-width: 768px){
.video-label {
  font-size: 0.5em !important;
}
}

.video-label-left {
  bottom: 10px;
  left: 10px;
}

.video-label-right {
  bottom: 10px;
  right: 10px;
}
</style>
